import request from "@/utils/request";
import qs from "qs";

//获取验证码
export const smsSend = (data) =>
  request.post("/api/asit-system/member/webSmsSend", qs.stringify(data));

//注册获取验证码
export const smsSendRegister = (data) =>
  request.post("/api/asit-system/member/sendRegisterValidate", qs.stringify(data));

//短信登录
export const webSmsLogin = (data) =>
  request.post("/api/asit-system/member/webSmsLogin", qs.stringify(data));

//账号登录
export const webLogin = (data) =>
  request.post("/api/asit-system/member/webLogin", qs.stringify(data));

//注册
export const register = (data) =>
  request.post("/api/asit-system/member/register", qs.stringify(data));

//获取已有国家
export const getNational = (data) => {
  return request({
    url: `/api/mask-api/mask/region-national`,
    method: 'get',
    params: data,
  })
}
//获取已有的省
export const getProvince = (data) => {
  return request({
    url: '/api/mask-api/mask/region-province',
    method: 'get',
    params: data,
  })
}
//下载PDF文件
export const getDownloadReport = (data) => {
  return request({
    url: '/api/mask-api/maskexamresult/generatePdf',
    method: 'get',
    params: data,
    responseType: "blob",
    header: { "Content-Type": "multipart/form-data" },
  })
}
//获取已有的市
export const getCity = (data) => {
  return request({
    url: '/api/mask-api/mask/region-city',
    method: 'get',
    params: data,
  })
}
//获取搜索区/县
export const getCounty = (data) => {
  return request({
    url: `/api/mask-api/mask/region-county`,
    params: data,
  })
}

//部门
export const getTree = (data) => {
  return request({
    url: '/api/mask-api/maskdept/page',
    method: 'get',
    params: data,
  })
}

//部门职能
export const getDeptduty = (data) => {
  return request({
    url: '/api/mask-api/maskdeptduty/page',
    method: 'get',
    params: data,
  })
}

//获取题目ID
export const getQuestion = (data) => {
  return request({
    url: '/api/mask-api/maskquestion/question-ids',
    method: 'get',
    params: data,
  })
}

//获取题目
export const getQuestionDetails = (data) => {
  return request({
    url: '/api/mask-api/maskquestion/detail',
    method: 'get',
    params: data,
  })
}

//提交答案
export const putResults = (data) =>
  request.post("/api/mask-api/maskexam/experience-results", data, {
    headers: {
      "content-type": "application/json",
    },
  });
  
//咨询意见
export const getPopinion = (data) => {
  return request({
    url: '/api/mask-api/maskexamresult/page',
    method: 'get',
    params: data,
  })
}

//咨询意见详情
export const getPopinionDetails = (data) => {
  return request({
    url: '/api/mask-api/maskexamresult/detail',
    method: 'get',
    params: data,
  })
}

//体检记录
export const getPhysicalDetails = (data) => {
  return request({
    url: '/api/mask-api/maskexam/page',
    method: 'get',
    params: data,
  })
}

//企业信息
export const getMemberDetail = (data) => {
  return request({
    url: '/api/asit-system/member/detail',
    method: 'get',
    params: data,
  })
}

//企业信息编辑
export const getMemberSubmit = (data) =>
  request.post("/api/asit-system/member/submit", data, {
    headers: {
      "content-type": "application/json",
    },
  });
  
//文件上传
export const uploadFile = (formData) => {
  return request({
    url: '/api/asit-resource/oss/endpoint/put-file-attach',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multuoart/form-data'
    },
  })
}
  
//企业信息编辑
export const getPersonagedetail = (data) => {
  return request({
    url: '/api/asit-system/member/detail',
    method: 'get',
    params: data,
  })
}

//续费
export const payment = (data) => {
  return request({
    url: '/api/asit-system/memberorder/createOrder',
    method: 'post',
    params: data,
  })
}

//修改密码
export const changePassword = (data) => {
  return request({
    url: '/api/asit-system/member/change-password',
    method: 'post',
    params: data,
  })
}

//获取临时Token
export const temporaryToken = (data) => {
  return request({
    url: '/api/asit-system/member/temporaryToken',
    method: 'post',
    params: data,
  })
}

//根据临时Token更改密码
export const tokenChangePassword = (data) => {
  return request({
    url: '/api/asit-system/member/tokenChangePassword',
    method: 'post',
    params: data,
  })
}

//会员详情
export const getMemberorderDetail = (data) => {
  return request({
    url: '/api/asit-system/memberorder/detail',
    method: 'get',
    params: data,
  })
}
