/**
 * Created by js5323
 */
import axios from "axios";
import store from "@/store/index";
import router from "../router";
import { AUTH_KEY } from "../config";
import qs from "qs";
import {Message} from 'element-ui';

const request = axios.create({
  timeout: 10000,
  headers: {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: AUTH_KEY,
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "comma" });
  },
});

request.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `${AUTH_KEY}`;

    if (store.getters.token) {
      config.headers['Asit-Auth'] = "bearer " + store.getters.token;
    }
    if(store.getters.userInfo.TemporaryToken){
      config.headers['Token'] = store.getters.userInfo.TemporaryToken;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    /**
     * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
     * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
     */
    let { data } = response;

    if (data.error_code) {
      return Promise.reject(data.error_description);
    }

    return data;
  },

  (error) => {
    console.log("err" + error); // for debug
    const { response } = error;
    const { data } = response;
    const message = data.msg || data.error_description || '未知错误';

    if (response && response.status === 401) {
      router.replace("/login");
    }
    if (response.status !== 200) {
      Message({
        message: message,
        type: 'error'
      });
      return Promise.reject(new Error(message))
    }

    if (data.error_code) {
      return Promise.reject(data.error_description);
    }

    return Promise.reject(data.msg);
  }
);

export default request;
